<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" showOutput :showSearch="true" @getList="getList"
			@clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.phone" size="small" clearable style="width: 140px" placeholder="按手机号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.status" size="small" clearable style="width: 100px" placeholder="审核状态">
					<el-option v-for="(item, index) in statusType" :label="item" :value="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.tm" clearable type="date" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" placeholder="提交时期" size="small">
				</el-date-picker>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="phone" title="用户信息" align="center" min-width="50px" />
			<vxe-table-column slot="table-item" field="path" title="行驶证" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-image style="width: 100px; height: 50px" fit="contain" :src="row.ext.path" :preview-src-list="[row.ext.path]"></el-image>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="path" title="行驶证" align="center" min-width="50px">
				<template v-slot="{ row }">
					<el-image style="width: 100px; height: 50px" fit="contain" :src="row.ext.back" :preview-src-list="[row.ext.back]"></el-image>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="city" title="来源" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="is_vip" title="用户属性" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="ext.allElectric" title="累计消费" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="tm" title="提交时期" align="center" min-width="80px" />
			<vxe-table-column slot="table-item" field="status" title="审核状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					{{ statusType[row.status] }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status" title="操作" align="center" min-width="80px">
				<template v-slot="{ row }">
					<el-button v-if="row.status == 0" size="small" plain @click="agree(row)">通过</el-button>
					<el-button v-if="row.status == 0" size="small" plain @click="cancel(row)">驳回</el-button>
				</template>
			</vxe-table-column>
		</Tables>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'market-activitycommon-newcaruser',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '新车活动用户',
				isLoading: false,
				tableData: [],
				statusType: {
					"0":"待审核",
					"1":"通过",
					"2":"驳回"
				},
				totalPage: 0,
				searchFormData: {
				},
				// 详情
				detailDialogShow: false,
				detailDialogFormData: {},
			}
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				this.searchFormData.activity_id = this.$route.query.id;
				params = Object.assign(params, this.searchFormData);
				const res = await this.$api.Market.ActivityCommon.newcaruser(params)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
			// 通过
			agree(row) {
				this.$confirm('确认审核通过?', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						activity_id: row.activity_id,
						fans_id: row.fans_id,
					}
					await this.$api.Market.ActivityCommon.newcaragree(params)
					this.$notify({
						title: '成功',
						message: '操作成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
			// 通过
			cancel(row) {
				this.$confirm('确认审核驳回?', '提示', {
					cancelButtonClass: 'btn-custom-cancel',
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const params = {
						token: this.$store.state.user.token,
						activity_id: row.activity_id,
						fans_id: row.fans_id,
					}
					await this.$api.Market.ActivityCommon.newcarcancel(params)
					this.$notify({
						title: '成功',
						message: '操作成功',
						type: 'success'
					})
					this.$refs.xTable.refreshTable()
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
